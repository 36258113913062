import { enableRipple } from "@syncfusion/ej2-base";
import { BrowserRouter } from "react-router-dom";
import Routes from "routes/Routes";
import { LoadingContextProvider } from "system/context/LoadingContext";
import { UserContextProvider } from "system/context/UserContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
enableRipple(true);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <LoadingContextProvider>
          <BrowserRouter>
            <Routes></Routes>
          </BrowserRouter>
        </LoadingContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  );
}

export default App;
