import { Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import TitleDiv from "components/Common/TitleDiv";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "style/form.css";
import { BodyTableCell, FormButton, FormDropDown, FormInput, LinkButton, TitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import ERPApi from "system/api/ERPApi";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { useUserState } from "system/context/UserContext";
import { SearchCSRType } from "system/CRMConstants";
import { CSRValidation, SOValidation } from "system/Helper";
import {
  BaseModel,
  BaseTypeModel,
  Branch,
  CRMFile,
  CSR,
  CSRSearch,
  CSRSearchList,
  INIT_CSR,
  INIT_CSRSEARCH,
  Message,
  SEND_MESSAGE,
  TreeCategory,
} from "system/types";
import { CRMRoutes } from "system/types/routeList";
import ClipBoardButton from "./BtnGroupClipboard";
import InfoDetailModal from "./Modal/InfoDetailModal";
import ASRecord from "./ReportASRecord";
import ReportCSInfo from "./ReportCSInfo";
import ReportCustInfo from "./ReportCustInfo";
import SendMessage from "./ReportMessage";
import ReportProdInfo from "./ReportProdInfo";
import ReportSO from "./ReportSO";
import ReportSOLine from "./ReportSOLine";

interface ReportMainParams {
  id: string;
}
function ReportMain() {
  const history = useHistory();
  const Loading = useLoadingDispatch();
  const user = useUserState();
  const { id } = useParams<ReportMainParams>();
  const [data, setData] = useState<CSR>(INIT_CSR);
  const [msg, setMsg] = useState<Message>(SEND_MESSAGE);
  // 고객 정보 검색 modal
  const [flag, setFlag] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [search, setSearch] = useState<CSRSearch>(INIT_CSRSEARCH);
  const [custList, setCustList] = useState<CSRSearchList[]>([]);
  const [itemCategory, setItemCategory] = useState<TreeCategory[]>([]);
  // 기준 정보
  const [address, setAddress] = useState<TreeCategory[]>([]);
  const [branch, setBranch] = useState<Branch[]>([]);
  const [ngCategory, setNGCategory] = useState<TreeCategory[]>([]);
  const [resultCategory, setResultCategory] = useState<TreeCategory[]>([]);
  const [activity, setActivity] = useState<BaseTypeModel[]>([]);
  const [project, setProject] = useState<BaseTypeModel[]>([]);
  const [costCenter, setCostCenter] = useState<BaseTypeModel[]>([]);
  const [issueType, setIssueType] = useState<BaseModel[]>([]);
  const [storageType, setStorageType] = useState<BaseModel[]>([]);
  const [salesPool, setSalesPool] = useState<BaseModel[]>([]);
  const [divmode, setDivmode] = useState<BaseModel[]>([]);

  const onChangeSearch = (e: any) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleOpenInfo = () => {
    if (search.text.length < 2) {
      alert("검색어는 2자 이상 입력해주세요.");
    } else {
      CRMApi.GetSearchResult(search)
        .then((res) => {
          // sql 같이 수정
          if (search.classify === "reportNum") {
            if (res.length === 1) {
              history.push(`${CRMRoutes.report}/${search.text}`);
            } else {
              alert("일치하는 문서번호가 없습니다.");
            }
          } else {
            setCustList(res);
            setOpenInfo(true);
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleOpenInfo();
    }
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  useEffect(() => {
    const fetchBaseData = async () => {
      try {
        Loading({ type: "LOADING" });
        // 기본 정보 가져오기
        const [categoryRes, branchRes, addressRes, soRes, ngCategoryRes, resultCategoryRes, apcRes] = await Promise.all(
          [
            CRMApi.GetItemCategory(false),
            UserApi.GetBranchList(),
            CRMApi.GetAddress(),
            CRMApi.GetSOInfo(),
            CRMApi.GetNGCategory(),
            CRMApi.GetResultCategory(),
            CRMApi.GetAPC(),
          ]
        );
        setItemCategory(categoryRes);
        setBranch(branchRes);
        setAddress(addressRes);
        setNGCategory(ngCategoryRes);
        setResultCategory(resultCategoryRes);

        const activityData = apcRes.filter((item: BaseTypeModel) => item.type === "activity");
        const projectData = apcRes.filter((item: BaseTypeModel) => item.type === "project");
        const costCenterData = apcRes.filter((item: BaseTypeModel) => item.type === "costcenter");

        setActivity(activityData);
        setProject(projectData);
        setCostCenter(costCenterData);

        const issueData = soRes.filter((item: BaseTypeModel) => item.type === "issue");
        const storageData = soRes.filter((item: BaseTypeModel) => item.type === "storage");
        const salespoolData = soRes.filter((item: BaseTypeModel) => item.type === "salespool");
        const divmodeData = soRes.filter((item: BaseTypeModel) => item.type === "divmode");

        setIssueType([{ id: "", name: "선택안함" }, ...issueData]);
        setStorageType([{ id: "", name: "선택안함" }, ...storageData]);
        setSalesPool([{ id: "", name: "선택안함" }, ...salespoolData]);
        setDivmode([{ id: "", name: "선택안함" }, ...divmodeData]);

        if (id) {
          CRMApi.GetCRMDetail(id)
            .then((res) => {
              setData(res);
              if (res.prodInfo.serialNum !== "") {
                setFlag(true);
              }
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              console.log(msg);
            });
          CRMApi.GetFileList(id)
            .then((res) => {
              setFileList(res);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              console.log(msg);
            });
        } else {
          const newData = { ...INIT_CSR };
          newData.csInfo = {
            ...newData.csInfo,
            receivedPerson: user.name,
            receivedBranch: user.branch,
            csReceivedTime: moment().format("HH:mm"),
          };
          setData(newData);
          setFlag(false);
        }
      } catch (err: any) {
        let msg = ErrorHandler(err);
        console.log(msg);
      } finally {
        Loading({ type: "COMPLETE" });
      }
    };

    fetchBaseData();
  }, [Loading, id, user.branch, user.name]);

  // 버튼
  const onClickClear = () => {
    const newData = { ...INIT_CSR };
    newData.csInfo = { ...newData.csInfo, receivedPerson: user.name, receivedBranch: user.branch };
    setData(newData);
    setFlag(false);
  };

  const onClickDelete = () => {
    if (window.confirm("삭제 후 복구할 수 없습니다. 정말 삭제하시겠습니까?")) {
      if (data.caseId && !data.csInfo.erp) {
        CRMApi.DeleteCSR(data)
          .then((res) => {
            if (res) {
              alert("삭제되었습니다.");
              history.push(CRMRoutes.search);
            } else {
              alert("삭제에 실패했습니다.");
            }
          })
          .catch(() => {
            alert("Error! 삭제에 실패했습니다.");
          });
      } else {
        alert("문서 번호가 있고 ERP에 올라가지 않은 경우만 삭제 가능합니다.");
      }
    }
  };

  const onSave = async (): Promise<boolean> => {
    if (!CSRValidation(data)) {
      return false; // 유효성 검사 실패 시 false 반환
    }

    Loading({ type: "LOADING" });
    try {
      const res = data.caseId ? await CRMApi.UpsertCSR(data) : await CRMApi.InsertCSR(data);
      await uploadFileToServer(res.caseId);
      alert(res.caseId + " 저장되었습니다.");
      history.push(`${CRMRoutes.report}/${res.caseId}`);
      setData(res);
      return true; // 성공 시 true 반환
    } catch (err: any) {
      let msg = ErrorHandler(err);
      console.log(msg);
      alert("저장에 실패했습니다.");
      return false; // 실패 시 false 반환
    } finally {
      Loading({ type: "COMPLETE" });
    }
  };

  const sendSO = async () => {
    try {
      const saveSuccess = await onSave();
      if (!saveSuccess) {
        return;
      }

      if (data.soList.some((so) => so.salesID === "")) {
        if (!SOValidation(data)) {
          return;
        }

        Loading({ type: "LOADING" });

        try {
          const res = await ERPApi.InsertCSRAndSO(data.caseId);
          if (res.result === "Pass") {
            setData(res.csr);
            alert("SO 생성 : " + res.salesID);
          } else {
            alert("ERP 연동 오류: " + res.message);
          }
        } catch (err: any) {
          alert("ERP 연동 중 오류 발생: " + err.message);
        }
      }
    } catch (err: any) {
      let msg = ErrorHandler(err);
      console.log(msg);
      alert("저장에 실패했습니다.");
    } finally {
      Loading({ type: "COMPLETE" });
    }
  };

  const onPrint = () => {
    if (data.caseId) {
      window.open(
        `${CRMRoutes.view}/${data.caseId}`,
        "PrintPage",
        "menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=1200px,height=1050px,left=0,top=0"
      );
    }
  };

  // 파일 첨부
  const [singleFile, setSingleFile] = useState<CRMFile>();
  const [fileList, setFileList] = useState<CRMFile[]>([]);

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setSingleFile({
        id: 0,
        caseId: "",
        filePath: "",
        fileName: file.name,
        note: "",
        formFile: file,
      });
    }
  };

  const deleteFile = (index: number) => {
    if (fileList[index].id !== 0) {
      CRMApi.DeleteFile(fileList[index])
        .then(() => {
          const updatedFileList = [...fileList];
          updatedFileList.splice(index, 1);
          setFileList(updatedFileList);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    } else {
      const updatedFileList = [...fileList];
      updatedFileList.splice(index, 1);
      setFileList(updatedFileList);
    }
  };

  const onSaveFile = () => {
    if (singleFile) {
      setFileList((prevFileList) => [...prevFileList, singleFile]);
      setSingleFile(undefined); // 업로드 후 singleFile 초기화
    }
  };

  const uploadFileToServer = async (caseId: string) => {
    for (const file of fileList) {
      const formData = new FormData();
      formData.append("formFile", file.formFile);
      formData.append("fileName", file.fileName);
      formData.append("caseId", caseId);
      formData.append("id", file.id.toString());

      try {
        const response = await CRMApi.UpdateFile(formData);
        if (response) {
          console.log("File uploaded successfully.");
        } else {
          console.error("Failed to upload file:", response.statusText);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const downloadFile = (index: number) => {
    const file = fileList[index];
    if (file && file.filePath) {
      fetch(file.filePath)
        .then((response) => response.blob())
        .then((blob) => {
          const blobObjectUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobObjectUrl;
          link.download = file.fileName;
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(blobObjectUrl);
        })
        .catch((error) => {
          console.error("Error downloading the blob:", error);
        });
    } else {
      console.error("Invalid file path or file does not exist.");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="Customer Service Report"></TitleDiv>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-start" alignItems="center">
        <FormButton onClick={onClickClear}>신규</FormButton>
        {data.caseId && <FormButton onClick={onPrint}>인쇄</FormButton>}
        {(["Admin", "HeadA", "HeadB"].includes(user.authority) ||
          (user.authority === "BranchUser" && user.branch === data.custInfo.branch)) &&
          data.caseId !== "" &&
          !data.csInfo.erp && <FormButton onClick={onClickDelete}>삭제</FormButton>}
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
        <ClipBoardButton data={data} branchList={branch} setData={setData} setMsg={setMsg}></ClipBoardButton>
      </Grid>
      <Grid item xs={10}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell align="center">고객정보검색</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    value={search.classify}
                    onChange={onChangeSearch}
                    name="classify"
                    cssClass="e-outline e-small"
                    fields={{ text: "key", value: "label" }}
                    dataSource={SearchCSRType}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormInput
                    name="text"
                    value={search.text}
                    onChange={onChangeSearch}
                    onKeyDown={handleKeyPress}
                    variant="outlined"
                    size="small"
                    fullWidth
                  ></FormInput>
                </BodyTableCell>
                <TitleTableCell align="center">제품검색</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="parentCategory"
                    cssClass="e-outline e-small"
                    onChange={onChangeSearch}
                    value={search.parentCategory}
                    fields={{ text: "id", value: "id" }}
                    dataSource={[{ id: "전체" }, ...itemCategory]}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="category"
                    cssClass="e-outline e-small"
                    onChange={onChangeSearch}
                    value={search.category}
                    fields={{ text: "name", value: "id" }}
                    dataSource={[
                      { id: "전체", name: "전체" },
                      ...(itemCategory.find((x) => x.id === search.parentCategory)?.children || []),
                    ]}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell style={{ whiteSpace: "nowrap" }}>
                  <FormButton onClick={handleOpenInfo}>조회</FormButton>
                  <FormButton onClick={onSave}>저장</FormButton>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {openInfo && (
          <InfoDetailModal
            open={openInfo}
            onClose={handleCloseInfo}
            custList={custList}
            setData={setData}
            setFlag={setFlag}
          />
        )}
      </Grid>
      {/* 고객 기본 정보 */}
      <ReportCustInfo
        data={data}
        setData={setData}
        flag={flag}
        setFlag={setFlag}
        branch={branch}
        address={address}
      ></ReportCustInfo>
      <ReportProdInfo
        data={data}
        setData={setData}
        flag={flag}
        setFlag={setFlag}
        itemCategory={itemCategory}
        branch={branch}
      ></ReportProdInfo>
      {/* AS 이력 리스트 */}
      <ASRecord data={data}></ASRecord>
      {/* CS 접수 및 처리 입력 */}
      <ReportCSInfo
        data={data}
        setData={setData}
        ngCategory={ngCategory}
        resultCategory={resultCategory}
      ></ReportCSInfo>
      {/* 고객 검색 및 SO 전송 */}
      <ReportSO
        data={data}
        sendSO={sendSO}
        setData={setData}
        activity={activity}
        project={project}
        costCenter={costCenter}
      ></ReportSO>
      <ReportSOLine
        data={data}
        setData={setData}
        issueType={issueType}
        storageType={storageType}
        salesPool={salesPool}
        divmode={divmode}
      ></ReportSOLine>
      {/* 파일 첨부 */}
      <Grid item xs={5}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell style={{ width: "80px" }} align="center">
                  파일첨부
                </TitleTableCell>
                <TitleTableCell align="center">
                  <input id="file" type="file" onChange={uploadFile}></input>
                </TitleTableCell>
                <TitleTableCell align="center">
                  <LinkButton onClick={onSaveFile}>업로드</LinkButton>
                </TitleTableCell>
              </TableRow>
              {fileList.map((file, index) => (
                <TableRow key={index}>
                  <TitleTableCell></TitleTableCell>
                  <BodyTableCell>{file.fileName}</BodyTableCell>
                  <BodyTableCell align="center">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <LinkButton onClick={() => downloadFile(index)}>다운</LinkButton>
                      <LinkButton onClick={() => deleteFile(index)}>삭제</LinkButton>
                    </div>
                  </BodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* 문자 전송 */}
      {user.authority !== "BranchUser" && (
        <Grid item xs={12}>
          <hr />
          <SendMessage msg={msg} csr={data}></SendMessage>
        </Grid>
      )}
    </Grid>
  );
}

export default ReportMain;
